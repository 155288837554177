import React from 'react'
import Illustration from 'assets/svg/not-found.svg'
import Link from 'components/Link'

import './styles.scss'

const NotFound = () => (
  <section className="container">
    <div className="columns not-found__section">
      <div className="column not-found__text">
        <h1 className="text-6xl text-black">iiihhh, caiu!</h1>
        <h2 className="font-light text-2xl text-grey-1">
          Parece que a página que você procura não está mais disponível
        </h2>
        <Link to="/">Voltar para o início</Link>
      </div>
      <div className="column not-found__illustration">
        <Illustration />
      </div>
    </div>
  </section>
)

export default NotFound
