import React from 'react'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import NotFound from 'components/NotFound'

import { isServerSide } from 'plugins/content'

function NotFoundPage() {
  if (isServerSide())
    return (
      <SEO
        title="iFood Institucional"
        description="Pensou comida, pensou iFood"
        keywords={[`ifood`, `tele-entrega`, `comida`, `restaurantes`]}
      />
    )

  return (
    <Layout>
      <SEO title="404 - Página não encontrada" description="Página não encontrada" />
      <NotFound
        title="iiihhh, caiu!"
        heading="Parece que a página que você procura não está mais disponível"
        link="Voltar para o início →"
      />
    </Layout>
  )
}

export default NotFoundPage
