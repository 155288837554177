import React, { useMemo } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import PropTypes from 'prop-types'

import './styles.scss'

const Link = ({ to, href, secondary, children, className, ...props }) => {
  const checkClassName = useMemo(() => {
    if (secondary) {
      return `link secondary ${className}`
    }
    return `link ${className}`
  }, [secondary, className])

  if (href) {
    return (
      <a
        className={checkClassName}
        href={href}
        rel="noreferrer noopener"
        target="_blank"
        {...props}
      >
        {children}
        <span>→</span>
      </a>
    )
  }

  return (
    <GatsbyLink to={to} className={checkClassName} {...props}>
      {children}
      <span>→</span>
    </GatsbyLink>
  )
}
Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  secondary: PropTypes.bool,
  children: PropTypes.string.isRequired,
}
Link.defaultProps = {
  to: undefined,
  href: undefined,
  secondary: false,
  className: '',
}

export default Link
